.gg-slider {
    width: calc(100% - 24px);
    display: flex;
    flex-direction: column;
    align-self: center;
    position: relative;
    top: -10px;

    .MuiTypography-root {
        color: rgba(0, 0, 0, 0.6);
        padding-left: 3px;
        font-size: 0.75em;
        // padding-bottom: 12px;
    }

    .MuiSlider-root{
        margin-bottom: 36px;
    }
}
