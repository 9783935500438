.home.flex-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 24px);
    background-color: #29434e;
    padding: 12px;

    .MuiCard-root.home-card {
        display: flex;
        flex-direction: column;
        max-width: 550px;
        align-items: center;
        padding: 12px 12px 48px 12px;
        overflow: initial;
        text-align: center;
        z-index: 100;

        .MuiCard-root.image-card {
            display: flex;
            position: relative;
            top: -15%;
            z-index: 100;
            height: 100px;
            padding: 6px 24px;
            align-items: center;
            img {
                width: 200px;
                height: 65px;
            }
        }
    }
}

