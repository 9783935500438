.item-catalogue-filter-section{
    margin-bottom: 12px;
    position: relative;


    // .search-filter-area{

        width: 50%;
        .search-input{
         width: 100%;  
        }
        .item-filter-section{
            position: absolute;
            width: 100%;
            z-index: 100;
            box-shadow: 0px 6px 6px 4px rgba(0, 0, 0, 0.25);
    
            .sub-section{
                 margin-bottom: 12px;
    
                .title{
                    font-weight: bold;
                }
    
                .clear-filter-wrapper{
                    margin-left: 16px;
                }
            }
    
        }
    // }

}