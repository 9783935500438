.bid-details-dialog {
    z-index: 1400;
    .MuiDialogContent-root {
        padding: 0;

        .coloured-section {
            padding: 12px 24px 6px;

            .title {
                margin-bottom: 6px;
            }
            .detail {
                .label {
                    min-width: 68px;
                }
            }
        }

        .content-wrapper {
            padding: 24px 24px 0 24px;
            .intro-wrapper {
                font-size: 0.9rem;
                margin-bottom: 12px;
            }
        }

        .bid-details-wrapper {
            margin-bottom: 12px;
            .detail {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 6px;
                .label {
                    font-size: 0.8rem;
                }
                .value {
                    display: flex;
                    margin-left: 6px;
                    font-size: 1.1rem;
                }
                .terms-and-conditions {
                    width: 100%;
                }
                .MuiCollapse-wrapper {
                    overflow-y: auto;
                    max-height: 200px;
                }
            }
        }
    }

    .pending {
        font-weight: bold;
        color: rgb(255, 153, 0);
    }

    .success {
        font-weight: bold;
        color: green;
    }
}
