.login.flex-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 24px);
    background-color: #29434e;
    padding: 12px;

    .MuiCard-root.login-card {
        display: flex;
        flex-direction: column;
        width: 550px;
        align-items: center;
        padding: 12px;
        overflow: initial;
        text-align: center;
        z-index: 100;

        h2 {
            margin: 0 0 12px 0;
        }

        .button-wrapper {
            width: 100%;
            // margin: 22px 0 0 0;
            display: flex;
            // flex-direction: column;

            .validation-text {
                font-size: 0.75em;
                color: red;
            }

            .MuiButton-root {
                // width: 100%;
                // margin-top: 2px;
            }
        }

        .MuiCard-root.image-card {
            display: flex;
            position: relative;
            top: -15%;
            z-index: 100;
            height: 100px;
            padding: 6px 24px;
            align-items: center;
            img {
                width: 200px;
                // hei/ght: 65px;
            }
        }
    }

    .medium-text {
        font-size: 0.85em;
    }

    .small-text {
        font-size: 0.75em;
        text-align: center;
        margin-top: 6px;

        a {
            color: blue;
        }

        &.white {
            width: 550px;
            color: white;

            a {
                color: white;
            }
        }
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .login.flex-container {
        .MuiCard-root.login-card {
            width: calc(100% - 24px);
        }

        .button-wrapper {
            flex-direction: column;

            #save-button{
                order: 1
            }

            #cancel-button{
                order: 2
            } 
        }

        .small-text {    
            &.white {
                width: unset;
            }
        }
    }
}
