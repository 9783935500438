.audit-log {
	.MuiCardContent-root{
        overflow-x: auto;
    }
    
	.accordion-wrapper {
		margin-bottom: 12px;

		.clear-filter-wrapper {
			margin-left: 16px;
		}
      
		.filter-accordian{
		    margin-bottom: 6px;

		    .MuiAccordionSummary-content{
		        display: flex;
		        align-items: center;
		        .title{
		            font-weight: bold;
		            margin-right: 12px;
		        }
		    }
		}
	}

    .type-wrapper{
        display: flex;
        align-items: center;

        .error-icon{
            margin-right: 3px;
            color: #d32f2f;
            font-size: 0.95rem;
        }
    }
}
