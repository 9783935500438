.login-button-wrapper{
    display: inline-block;

    a{
        cursor: pointer;
    }

    .user-wrapper{
        cursor: pointer;
        display: flex;
        svg{
            margin-right: 6px;
        } 
    }
}

#basic-menu{
    .MuiPaper-root{
        top: 64px!important;
    }
}


.MuiMenu-list{
    a{
        color: rgba(0, 0, 0, 0.87);
        text-decoration: none;
    }
}