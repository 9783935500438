.payment-progress-dialog{
    z-index: 1500 !important;
    .MuiPaper-root {
        width: 450px;

    }
    .MuiDialogContent-root{
        padding: 0;

        .pull-right {
            float: right;
        }

        .small-font {
            font-size: 13px;
        }

        .blue-text {
            color: #072C3A;
        }

        .content-section{
            padding: 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .button-section{
            padding: 12px;
            display: flex;
            justify-content: flex-end;
        }

        .error-text {
            display: flex;
            align-items: center;

            img {
                padding-right: 12px;
            }

            svg {
                color: #26C0B9;
                vertical-align: bottom;
                padding-right: 12px
            }
        }

        .margin-20 {
            margin: 20px
        }

        .list-item {
            margin-bottom: 10px;

            img {
                padding-right: 6px;
            }
        }

    }
}