.mobile-bid-card {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 12px;
    margin-bottom: 12px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 20px;

    .value-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 12px;

        .bid-status-indicator {
            border-radius: 50%;
            padding: 12px;
            color: white;
            margin-right: 12px;
            height: 30px;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.failed {
                background-color: red;
            }

            &.pending {
                background-color: rgb(255, 153, 0);
            }

            &.succeeded {
                background-color: #7FC887;
            }
        }

        .value-status-wrapper {
            display: flex;
            flex-direction: column;

            .value {
                font-size: 1.3rem;
            }

            .status{
                font-size: 0.85rem;
            }
        }

    }

    .lot-title {
        display: block;
        margin-bottom: 12px;
    }

    .detail-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 6px;

        .detail-icon {
            display: flex;
            border-radius: 50%;
            padding: 7px;
            color: white;
            background-color: rgb(199, 199, 199);
            margin-right: 6px;

            svg {
                font-size: 1.1rem;
            }
        }

        .detail-text {
            font-size: 0.9rem;
        }
    }
}