.bigscreen-countdown-timer{
    display: flex;
    flex-direction: column;
    align-items: center;

    .title{
        font-size: 1.5rem;
        margin-bottom: -15px
    }

    .timer{
        font-size: 3.5rem;
    }

    .closed{ 
        font-size: 2.5rem;
    }
}