.media-section{
    .media-thumbnail{
        width: 120px;
        height: 80px;
    }

    .MuiTable-root{
        a{
            color: unset;
            
        }
    }

    [data-testid='VisibilityIcon'] {
        color: gray;
    }

    [data-testid='VisibilityOffIcon'] {
        color: gray;
    }

    .button-wrapper{
        #add-video-button{
            margin-right: 12px;
        }
    }
    
}

@media only screen and (max-width: 600px) {
    .media-section{
        .button-wrapper{
            flex-direction: column;

            #add-video-button{
                margin-right: unset;
                order: 2;
            }

            #add-image-button{
                order: 1;
                margin-bottom: 12px;
            }
        }
        
    }
}