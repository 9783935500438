.color-popover .MuiPaper-root{
    width: unset;
    padding: 18px 18px 6px 18px;
    background-color: transparent;
    box-shadow: none;

    .hex-input{
        width: calc(100% - 26px);
        padding: 6px 12px;
        border-radius: 6px;
        border: 1px solid gray;
        margin-top: 2px;
    }
}