.insert-popover {
    .popover-content {
        padding: 12px;
        display: flex;
        flex-direction: column;
        max-width: 100%;
        width: 800px;
    }

    .input-wrapper{
        margin-bottom: 0px;
    }

    .action-section {
        display: flex;
        justify-content: flex-end;

    }
}

@media only screen and (max-width: 600px) {
    .insert-popover {
        .popover-content {
            width: unset;
        }
    
        .input-wrapper{
            padding: 0px;
        }
    
        .action-section{
            width: 100%;
        }

        .save-button-wrapper {
            align-items: unset;
            flex-grow: 1;

            .buttons{
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                #cancel-button{
                    order: 1;
                }

                #save-button{
                    order: 0;
                }
            }
        }
    }
}