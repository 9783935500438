.big-screen-vertical-card-wrapper {
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    img {
        min-width: 400px;
        height: 266px;
        border-radius: 12px 0 0 12px;
    }

    .card {
        background-color: white;
        padding: 12px;
        width: 100%;
        border-radius: 0 12px 12px 0;
        display: flex;
        flex-direction: column;
        gap: 12px;
        justify-content: space-between;

        .lot-info-section {
            display: flex;
            flex-direction: column;
            gap: 6px;

            .title {
                font-size: 2.5rem;
                line-height: 2.8rem;
                font-weight: 600;
            }
        }

        .lot-specifics-wrapper {
            padding-top: 6px;

            >div {
                display: flex;
                flex-direction: row;
                font-size: 1.4rem;

                &:not(:last-child) {
                    margin-bottom: 12px;
                }

                .value {
                    font-weight: bold;

                    .min-bid-text {
                        font-size: 0.9rem;
                    }

                }

                .multiple-versions-text {
                    margin: 24px 0;
                }
            }
        }

    }
}