.confirmation-dialog{
    z-index: 1500 !important;
    .MuiPaper-root {
        width: 450px;

    }
    .MuiDialogContent-root{
        padding: 0;

        .coloured-section{
            padding: 50px 12px;
            display: flex;
            align-items: center;
            justify-content: center;

            img{
                width: 150px;
                height: 150px;
            }
        }

        .content-section{
            padding: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

        .button-section{
            padding: 12px;
            display: flex;
            justify-content: flex-end;

            .MuiButton-root .bb-button{
                background-color: #44CF6C;
                height: 50px;
            }
        }
    }
}

@media only screen and (max-width: 635px) {
    .MuiDialogContent-root{
        .button-section{ 
            flex-direction: column;
            .gg-button{
                height: 56px;
            }

            #save-button{
                order: 0
            }
            #cancel-button{
                order: 1
            }
        }
    }
}