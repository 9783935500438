.pin-wrapper {
    .MuiFormLabel-root {
        margin-bottom: 2px;
    }
    .pincode-input-container {
        .pincode-input-text {
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -webkit-text-security: disc;
            -moz-text-security: disc;
        }
    }
    .MuiFormHelperText-root {
        margin-left: 14px;
    }
}
