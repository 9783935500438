.back-hyperlink-wrapper{
    margin-bottom: 24px;

    span{
        display: flex;
        align-items: center;
        text-decoration: none;
        cursor: pointer;

        span{
            line-height: 1rem;
        }

        svg{
            margin-right: 2px;
            width: 20px;
            height: 20px;
        }
    }
}