.smart-tags-wrapper {
    margin: 6px 0 24px;
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;

    .no-margin {
        margin: 0
    }
}
