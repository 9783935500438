.youtube-thumbnail-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .youtube-icon {
        height: 50px !important;
        width: 50px !important;
        position: absolute;
    }

    img{
        aspect-ratio: 3/2;
    }

    .non-swiper-image {
        height: 100%;
        width: 100%;
        -webkit-background-size: cover;
        background-size: cover;
        background-repeat: no-repeat;
        -webkit-background-position: center;
        background-position: center;
        object-fit: cover;
    }
}
