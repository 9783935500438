.layout-authenticated {
	.full-height-content {
		padding: 24px 48px 80px 48px;
		margin: auto auto auto 240px;
		// min-height: calc(100vh - 314px);
	}

	.mobile-drawer {
		display: none;
		flex-shrink: 0;
		z-index: 1;
	}

	.desktop-drawer {
		display: block;
		flex-shrink: 0;
		z-index: 1;
	}

	.header-bar {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 64px;
		// Reduce the size of the app bar by the width of the drawer and shift it left.
		margin-left: 240px;
		width: calc(100% - 24px - 240px);
		height: 40px;
		// background-color: red;
		background-color: #26c0b9;
		border-bottom: solid 1px #22b1aa;
		padding: 12px;
		font-weight: 600;

		span {
			color: white;
			font-size: 1.3em;
		}

		.MuiIconButton-root {
			display: none;
		}

		.MuiButton-root:hover {
			background-color: unset;
		}

		.clickable-icon {
			color: white;
		}

		.authorised-email {
			color: white;
			font-size: 0.75em;
		}
	}
}

.drawer-paper {
	width: 240px;
	z-index: 1001 !important;

	.drawer-container {
		overflow: auto;
		height: 100%;

		.MuiAppBar-colorPrimary {
			background-color: #26c0b9;
			border-bottom: solid 1px;
			box-shadow: none;
			width: 241px;
			left: 0;

			.MuiToolbar-root {
				a {
					display: flex;

					img {
						max-height: 50px;
					}
				}
			}

		}

		.menu-bar {
			.clickable-icon {
				display: flex;
			}
		}

		.list-container {
			padding-top: 64px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: calc(100% - 65px);

			.MuiList-root {
				.MuiMenuItem-root {
					.MuiListItemIcon-root {
						svg {
							color: rgba(0, 0, 0, 0.87);
						}
					}

					&:hover {
						.MuiListItemIcon-root {
							svg {
								color: crimson;
							}
						}
					}
				}
			}

			.MuiTypography-body1 {
				font-size: 0.9rem;
				font-family: "Poppins", "Helvetica", sans-serif;
			}

			.MuiListItemText-root.bold span {
				font-weight: bold;
			}
		}
	}
}

/* Tablets */
@media only screen and (max-width: 767px) {
	.layout-authenticated {
		.full-height-content {
			margin: auto;
		}

		.header-bar {
			margin-top: 56px;

			.MuiAppBar-root {
				height: 56px;
			}
		}

		.header-bar {
			margin-top: 56px;
			margin-left: 0;
			width: calc(100% - 24px);
			padding: 6px 12px;

			.MuiIconButton-root {
				display: block;
			}
		}

		.mobile-drawer {
			display: block;
		}

		.desktop-drawer {
			display: none;
		}
	}
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	.layout-authenticated {
		.full-height-content {
			padding: 12px 12px 80px 12px;
		}

		.header-bar {
			margin-top: 56px;

			.MuiAppBar-root {
				height: 56px;
			}

			.MuiIconButton-root {
				display: flex;
				color: white;
			}
		}
	}

	.drawer-paper {
		.drawer-container {
			.MuiAppBar-colorPrimary {
				width: 240px;
			}

			.list-container {
				padding-top: 56px;
				height: calc(100% - 56px);
			}

			// .MuiList-root {
			// 	padding-top: 57px;
			// }
		}
	}
}