.winner-details-section{
    table{

        tr{
            // display: flex;
            // flex-direction: row;
        }

        td{
            // display: flex;
            // flex-direction: row;
            // align-items: flex-end;
            padding-right: 12px;
        }


        td.label{
            font-size: 0.8rem;
            // text-align: right;
        }
        td.value{
            font-size: 0.8rem;
            font-weight: bold;
        }
    }

a{
    color: blue
}
}