.loading-spinner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 12px;
    
    span {
        margin-top: 12px;
        font-style: italic;
    }
}