.MuiButton-root.tag-button {
    &:not(:last-child) {
        margin-right: 12px;
    }

    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin-bottom: 12px;

    &.selected {
        border: 1px solid rgba(0, 0, 0, 0.8);
        font-weight: 600;
    }

    &.filled {
        background-color: #26c0b9;
        color: white;
        font-weight: bold;
    }

    transition: transform 200ms;

    &:hover {
        transform: translateY(-3px);
    }
}