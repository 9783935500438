.login {

    .watermark{
        position: fixed;
        bottom: -416px;
        right: -209px;
        width: 1000px;
        opacity: 3%;
    }

    .header-wrapper{
        display: flex;
        flex-direction: column;
        // align-items: center;
        margin-bottom: 12px;

        // h2{
        //     color: white;
        //     margin: 0 0 12px;
        // }

        img{
            width: 200px;
        }
    }


    .MuiTabPanel-root {
        width: 100%;
        padding-bottom:0;
    }
    .MuiTextField-root {
        width: 100%;
        margin-bottom: 24px;
    }
    
    .MuiButtonBase-root {
        align-items: center;
        text-align: center;
    }

    

    .action-section {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .save-button-wrapper{
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .validation-text{
            margin-bottom: 6px;
        }

    }

}

.forgot-password-link {
    text-align: left;
    margin-top: -15px;
    margin-bottom: 15px;
    font-size: 0.9rem;
    cursor: pointer;
}

.back-hyperlink-wrapper{
    font-size: 0.9rem;
}

.forgot-password{
    .intro-text{
        display: block;
        font-size: 0.9rem;
    }

    .button-wrapper{
        margin-top:0;
    }
}

.login-dialog{
    //needs to be higher than header bar
    z-index: 1600 !important;
    .MuiDialogContent-root{
        padding: 12px;

        .MuiTabPanel-root{
            padding: 24px 0 0 0;
        }
    }
}

.center-aligned {
    display: flex;
    align-items: center;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .login {
        overflow: hidden;
        .save-button-wrapper{
            align-items: unset;
            
            .buttons{
                display: flex;
                flex-direction: column;
                #login-button{
                    order: 1
                }
    
                #cancel-button{
                    order: 2
                }
            }

        }

        .watermark{
            bottom: -100px;
            right: -100px;
            width: 400px;
            height: auto;
        }
    }
}
