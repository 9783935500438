.user-list {
    
    .bulk-actions{
        width: 200px;
    }

    a{
        color: unset;
        text-decoration: unset;
    }
    
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .campaign-list-page {
        .button-wrapper{
            a{
                width: 100%;
            }
        }
        
    }
}
