.campaign-menu-bar-wrapper {
	.campaign-menu-bar {
		--menu-bar-font-color: white;
		--menu-bar-background-color: white;

		.MuiAppBar-root {
			position: fixed;
			box-shadow: none;
			z-index: 1400; //make the nav sit above the drawer in mobile
		}

		.MuiAppBar-colorPrimary {
			flex-grow: 1;
			background-color: var(--menu-bar-background-color);
			color: white;

			.authorised-email {
				display: flex;
				cursor: pointer;

				svg {
					color: white;
					margin-left: 6px;
				}
			}
		}

		.MuiToolbar-root {
			justify-content: space-between;
			padding: 0px 24px;
			align-items: unset;

			.logo-wrapper {
				display: flex;
				align-items: center;
				padding: 3px 0;

				a {
					display: flex;

					img {
						max-width: 120px;
						max-height: 55px;
					}
				}
			}

			// .lots-button-wrapper {
			// 	a {
			// 		button {
			// 			font-size: 0.8rem;
			// 			min-width: unset;
			// 			padding: 0 12px;
			// 		}
			// 	}
			// }


			.lots-button-wrapper {
				display: flex;
				align-items: center;
			
				a {
					text-decoration: none;
					color: unset;
			
					.MuiButton-root {
						--border-size: 2px;
						font-size: 0.8rem;
						min-width: unset;
						padding: 0 12px;
			
						background-color: var(--background-color);
						min-height: 40px;
						position: relative;
						z-index: 2;
						border-radius: 20px;
			
						&:hover{
							background-color: var(--background-color);
							opacity: 0.8;
							// transform: translateY(-3px);
						}
			
						transition: opacity 200ms;
			
			
					}
				}
			}








			.menu-items {
				display: flex;
				align-items: center;

				ul {
					margin: 0 35px 0 0;
					padding: 0;
					list-style: none;

					li {
						display: inline-block;
						margin-left: 35px;

						a {
							text-decoration: none;
							text-transform: uppercase;
						}

						&:hover {
							opacity: 0.8;
							// transform: translateY(-3px);

						}

						transition: opacity 200ms;
					}
				}
			}

			.hamburger-menu-wrapper {
				display: none;

				.hamburger-menu {
					justify-content: flex-end;
					cursor: pointer;
					margin-left: 12px;

					-webkit-tap-highlight-color: transparent;
					-webkit-touch-callout: none;
					-webkit-user-select: none;
					-khtml-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;

					&:focus {
						outline: none !important;
					}

					.hamburger {
						padding: 0px;

						.hamburger-inner {
							background-color: var(--menu-bar-font-color);

							&::before {
								background-color: var(--menu-bar-font-color);
							}

							&::after {
								background-color: var(--menu-bar-font-color);
							}
						}
					}
				}
			}
		}
	}
}


.menu-drawer {
	.MuiDrawer-paper {
		background-color: var(--menu-bar-background-color);
		padding-bottom: 12px;
		margin-top: 56px;

		a {
			color: var(--menu-bar-font-color);
			text-decoration: none;

			.MuiMenuItem-root {
				font-weight: 600;
				font-size: 1em;
				font-family: "Poppins", "Helvetica", sans-serif;
			}
		}

		.close-icon-container {
			height: 56px;
			display: flex;
			justify-content: flex-end;

			svg {
				color: var(--menu-bar-font-color);
			}
		}
	}
}


//Tablets
@media only screen and (max-width: 1180px) {

	.campaign-menu-bar-wrapper {
		.campaign-menu-bar {
			.MuiToolbar-root {
				.menu-items {
					ul {
						li {
							a {
								padding: 12px 0;
							}

						}
					}
				}

			}
		}
	}
}


@media only screen and (max-width: 900px) {
	.campaign-menu-bar-wrapper {
		.campaign-menu-bar {
			.MuiToolbar-root {
				

				.menu-items {
					display: none;
				}

				.hamburger-menu-wrapper {
					display: flex;
					align-items: center;

					.hamburger-menu {
						align-items: center;
					}
				}

			}
		}
	}
}
@media only screen and (max-width: 635px) {
	.campaign-menu-bar-wrapper {
		.campaign-menu-bar {
			.MuiToolbar-root {
				padding: 0 14px 0 12px;

				.logo-wrapper {
					a {
						img {
							max-height: 49px;
						}
					}
				}
			}
		}
	}
}