.ad-hoc-dialog{

    //passed in from application logic
    --dialog-width: 50%;

    .MuiPaper-root{

        width: var(--dialog-width);
        max-width: var(--dialog-width);

        .MuiDialogContent-root{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 24px;

            .ql-editor{
                overflow: hidden;
            }
    
            .title {
                margin: 12px 0;
                font-size: 3rem;
                text-align: center;
            }
    
            .body{
                font-size: 2rem;
                text-align: center;
            }
    
            img{
                width: 900px;
            }

            .bigscreen-countdown-timer{
                margin: 72px 0;
                .title {
                    margin: 0;
                 }

                 .timer{
                    font-size: 7.5rem;
                    line-height: 7.5rem;
                 }
            }
        }
    }
}