.recipients-dialog-full-width {
    
    .MuiDialog-container {
        
        .MuiPaper-root {
            max-width: 100%;
            width: 100%;
        }

        .MuiDialogContent-root {
            display: flex;
            justify-content: center;
            padding: 20px;
        }
    }
}

.recipients-dialog-half-width {
    
    .MuiDialog-container {
        
        .MuiPaper-root {
            max-width: 100%;
            width: 50%;
        }

        .MuiDialogContent-root {
            display: flex;
            justify-content: center;
            padding: 20px;
        }
    }
}