.MuiDialog-container {

    .MuiDialogContent-root {
        .MuiFormControl-root:first-child {
            padding-top:6px !important;

            .MuiInputLabel-root {
                padding-top:6px !important;
            }
        }
    }

    .coloured-section{
        .title{
            margin: 0 0 12px;  
        }
    }

    .content-wrapper{
        .field-wrapper:last-child{
            .MuiFormControl-root{
                margin-bottom: 0;
            }
        }
    }

    .MuiDialogActions-root{
        .action-section{

            .save-button-wrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
        
                .validation-text{
                    margin-bottom: 6px;
                    font-weight: normal;
                    font-size: 0.8em;
                }
        
            }
        }
    }


    .intro-text{
        margin-bottom: 6px;
    }

    .success{
        font-weight: bold;
        color: green;
    }

    .danger{
        font-weight: bold;
        color: red;
    }
}

@media only screen and (max-width: 600px) {
    .MuiDialog-container {
        .action-section{
            width: 100%;
        }

        .save-button-wrapper {
            align-items: unset;
            flex-grow: 1;

            .buttons{
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                #cancel-button{
                    order: 1;
                }

                #save-button{
                    order: 0;
                }
            }
        }

        
    }
}
