.admin-footer-section {
	display: flex;
	flex-grow: 1;
	flex-direction: row;
	background-color: #121824;
	padding: 24px;
	color: white;
	align-items: center;
	bottom: 0;
	margin: 48px 0 0 241px;

	.left-content {
		display: flex;
		flex: 1;
		flex-direction: column;
		font-size: 0.7em;

		span {
			margin-bottom: 6px;
		}
	}

	.middle-content {
		display: flex;
		flex-grow: 1;
		flex-basis: 0;
		justify-content: center;
		text-align: center;
		font-size: 0.7em;
		flex-direction: column;

		a {
			margin-top: 6px;
		}

		img {
			width: 50px;
			height: 45px;
			margin-bottom: 12px;
		}
	}

	.right-content {
		display: flex;
		flex-direction: column;
		flex: 1;
		// justify-content: flex-end;
		align-items: flex-end;
		font-size: 0.7em;

		.social-wrapper {
			display: flex;

			a {
				display: flex;
				.social-icon {
					width: 24px;
					height: 24px;
					margin-left: 12px;
				}
			}
		}
	}

	a {
		color: inherit;
	}
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	.admin-footer-section {
		margin: 0;
		flex-direction: column;

        .middle-content {
            margin-bottom: 6px;
        }
	}
}
