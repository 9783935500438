.winner-report-page {

    .lots-won-section,
    .audit-log-table {
        border: solid 1px rgb(206, 206, 206);
        border-radius: 4px;
    }

    .lots-won-section {
        .MuiTable-root {
            .MuiTableRow-root:nth-last-child(2) {
                .MuiTableCell-root {
                    border-bottom: 1px solid grey;
                }
            }
        }
    }

    .MuiTable-root {
        th,
        td {
            padding: 2px 16px;
        }


        .MuiTableHead-root {
            background-color: #08415c;

            th {
                font-weight: bold;
                color: white;
            }
        }

        &.lots-won-table{
            .MuiTableBody-root {
                .MuiTableRow-root:nth-child(even) {
                    background-color: rgb(240, 240, 240);
                }
    
    
                .MuiTableRow-root:last-child {
                    .MuiTableCell-root {
                        border-bottom: none;
                    }
                }
            }
        }

        &.winners-table{
            .clickable-row{
                cursor: pointer;
            }
        }

    }

    // .tick-icon {
    //     color: #058c42;
    // }

    // .cross-icon {
    //     color: #cc2936;
    // }

    // .mid-icon {
    //     color: #eac435;
    // }

    
    .fixed-action-section {
        .all-winner-actions{
            width: 250px;

            



        }
        .right-hand-buttons {
            > *:not(:last-child) {
                margin-right: 6px;
            }
        }
    }
}

//TODO
#menu-allWinnerActions, #menu-winnerActions, #menu-itemActions{   
    .MuiMenuItem-root{
        .MuiListItemIcon-root{
            color: rgba(0, 0, 0, 0.87);
        }

        &:hover{
            svg{
                color: crimson;
            }
        }
    }    
}

