.item-details-dialog.MuiDialog-root{
        .MuiPaper-root{
            width: unset;

            .first-row{
                display: flex;
                flex-direction: row;

                .item-details-wrapper{
                    display: flex;
                    flex-direction: column;
                    margin-left: 12px;

                    >* {
                        margin: 0 0 12px 0;
                    }

                    .sub-title{
                        font-style: italic;
                        font-weight: 300;
                        font-size: 1.4rem;
                    }

                    .lot-specifics-wrapper {
                        padding-top: 6px;
            
                        > div {
                            display: flex;
                            flex-direction: row;
                            font-size: 1rem;
            
                            &:not(:last-child) {
                                margin-bottom: 6px;
                            }
            
                            .value {
                                font-weight: bold;
            
                                .min-bid-text {
                                    font-size: 0.9rem;
                                }
                            }
            
                            .multiple-versions-text {
                                margin: 24px 0;
                            }
                        }
                    }

                    
                }
            }

            .media-carousel{
                width: 400px;
                margin-bottom: 24px;
            }
            .placeholder-image{
                margin-bottom: 24px;
            }
            .description{
                margin: 0 0 12px;
            }
        }
    }
