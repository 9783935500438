.organisation-summary-page{
    .organisation-details-section{
        display: flex;
        flex-direction: row;
        align-items: center;

        .organisation-logo-wrapper{
            margin-right: 24px;
            img{
                max-height: 150px;
            }
        }

        .organisation-name-wrapper{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            color: black;

            >*{
                margin: 0 0 6px 0;
            }

            h2{
                font-size: 1.8rem;
            }
        }
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .organisation-summary-page{
        .organisation-details-section{
            flex-direction: column;
            .organisation-logo-wrapper{
                margin-right: 0;
                img{
                    max-height: 150px;
                }
            }
    
            .organisation-name-wrapper{
                text-align: center;              
                align-items: center;
                
                h2{
                    // font-size: 1.5rem;
                }
            }
        }
    }
}