.layout-authenticated-no-menu{
    .full-height-content {
        padding: 24px 48px 96px 48px;
        margin: 65px auto auto auto;
        // min-height: calc(100vh - 261px);
    }

    .admin-footer-section{
        margin: 48px 0 0 0;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .layout-authenticated-no-menu{
        .full-height-content {
            padding: 12px 12px 96px 12px;
            margin: 58px auto auto auto;
        }
    }
}