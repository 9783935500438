.summary-figure-card{
    width: calc(33% - 29px);
    padding: 12px;
    display: flex;
    flex-direction: column;

    //first 3 elements
    // &:nth-child(-n+3){
        margin-bottom: 12px;
    // }

    //every 3rd element
    &:not(:nth-child(3n)){
        margin-right:12px;
    }

    .label{
        font-size: 0.8rem;
    }

    .value{
        font-size: 2rem;
        text-align: center;
        padding: 24px 0;
    }

}

@media only screen and (max-width: 1060px) {
    .summary-figure-card{
        width: calc(50% - 30px);
        
        //every 3rd element
        &:not(:nth-child(3n)){
            margin-right:unset
        } 

        &:nth-child(odd){
            margin-right: 12px;
        }
        
        
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 450px) {
    .summary-figure-card{
        width: 100%;
        
        &:nth-child(odd){
            margin-right: unset;
        }
        
        
    }
}