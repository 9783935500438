.big-screen-carousel {
    padding: 24px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    .swiper {
        height: 85vh;
        width: 90vw;
        position: absolute !important;
        
    }

    &.horizontal{
        .swiper{
            display: flex;
        }

    }

    &.vertical{
        .swiper {   
            .swiper-wrapper {
                transition-timing-function: linear;
            }
        }

    }

    

}