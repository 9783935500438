.smart-tags-wrapper {
    svg {
        font-size: 14px !important;
        vertical-align: middle;
    }


    .clipboard-icon
    {
        font-size: 14px !important;
        display: inline-block !important;
    }

    .copyable-icon-and-text {
        // display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .copyable-icon-and-text:hover {
        cursor: pointer;
        text-decoration: none;
    }

    .copyable-icon-and-text:hover .clipboard-icon {
        color: crimson;
    }  

    .copyable-icon-and-text:hover .copyable-text {
        text-decoration: underline;
    }  

    .green {
        color: green
    }
}