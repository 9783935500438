.content {
	max-width: 1080px;
	width: calc(100% - 24px);
	margin: 0 auto;
	padding: 0 12px;
	z-index: 2;
	// position: relative;
	// min-height: calc(100vh - 261px);
}

.d-none {
	display: none;
}

.header-section {
	display: flex;
	align-items: center;
	padding: 12px 24px;
	font-size: 1.2em;
	font-weight: bold;
	margin-top: 64px;
	text-align: left;
	// background-color: #26c0b9;
	color: white;

	a {
		svg {
			display: flex;
			align-items: center;
			margin-right: 12px;
			cursor: pointer;
			color: white;
		}
	}
}

.MuiCardContent-root:last-child {
	padding-bottom: 16px !important;
}

.button-wrapper {
	&.space-between {
		justify-content: space-between;
	}

	display: flex;
	margin-top: 12px;
	justify-content: flex-end;
	align-items: center;

	a {
		text-decoration: unset;
		// margin-top: 16px;
		text-decoration: unset;
		color: unset;
	}

	.right-hand-buttons {
		.MuiButton-root {
			// margin: 16px 0 0 24px;
		}
	}
}

.MuiButton-root.gg-button {
	font-family: "Poppins", "Helvetica", sans-serif;
	color: white;
	background-color: #26c0b9;
	font-weight: 500;
	width: fit-content;
	padding: 6px 26px;
	min-width: 160px;

	&:hover {
		background-color: #187974;
	}

	&.confirm {
		background-color: green;
	}

	&.disabled {
		background-color: grey !important;
	}
}

.MuiButton-root.filter-button {
	&:not(:last-child) {
		margin-right: 12px;
	}

	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	margin-bottom: 12px;

	&.selected {
		border: 1px solid rgba(0, 0, 0, 0.8);
		font-weight: 600;
	}

	&.filled {
		background-color: #26c0b9;
		color: white;
		font-weight: bold;
	}

	transition: transform 200ms;

	&:hover {
		transform: translateY(-3px);
	}
}

.bulk-actions {
	width: 200px;
}

#menu-bulkActions {
	.MuiListItem-root {
		cursor: pointer;

		&:hover {
			background-color: #26c0b9;
			color: white;
		}
	}
}

h2.subtitle {
	margin-bottom: 0px;
}

.description {
	padding-bottom: 6px;
	font-size: 0.9rem;
}

.input-wrapper {
	margin: 12px 0 36px;
	padding: 12px;

	.sub-title {
		display: block;
		font-weight: bold;
		font-size: 1.1em;
		margin-bottom: 6px;
	}

	.description {
		display: block;
		font-size: 0.9em;
		margin-bottom: 12px;
	}

	.field-wrapper {
		display: inline-flex;

		width: calc(50% - 6px);
		margin-bottom: 24px;
		justify-content: center;

		&:nth-child(odd) {
			margin-right: 12px;
		}

		.MuiFormControl-root {
			width: 100%;
		}


		&.quill {
			flex-direction: column;
			position: relative;
			top: -20px;
			margin-bottom: 0;

			>span {
				padding-left: 14px;
				font-size: .75em;
				padding-bottom: 6px;
			}
		}
	}

	.tiptap {
		flex-direction: column;

		>span {
			padding-left: 14px;
			font-size: 0.75em;
			padding-bottom: 6px;
		}
	}

	.MuiFormControl-root {
		// width: calc(50% - 6px);
		// margin-bottom: 24px;

		&.color-picker {
			.MuiInputBase-input.Mui-disabled {
				color: rgba(0, 0, 0, 0.87);
				cursor: pointer;
			}

			.MuiFormLabel-root {
				color: rgba(0, 0, 0, 0.54);
			}
		}
	}

	.color-picker-wrapper {
		width: calc(50% - 6px);

		.color-picker {
			width: 100%;
		}
	}
}

.no-entries {
	display: flex;
	justify-content: center;
	font-size: 1.05em;
	font-style: italic;
	color: gray;
	text-align: center;
}

.MuiTable-root {
	.table-header-cell {
		display: flex;

		font-weight: bold;
		// text-align: center;

		//stop highlighting on double click
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		&.sortable {
			cursor: pointer;

			span:hover {
				text-decoration: underline;
			}
		}
	}

	.totals-row {
		span {
			font-weight: bold;
		}
	}

	.action-cell {
		display: flex;
		justify-content: flex-end;

		div:not(:last-child) {
			margin-right: 4px;
		}

		svg {
			color: rgba(0, 0, 0, 0.87);

			&.disabled {
				color: rgba(105, 105, 105, 0.87);

				&:hover {
					cursor: unset;
					color: rgba(105, 105, 105, 0.87);
				}
			}

			&:hover {
				cursor: pointer;
				color: crimson;
			}
		}
	}

	.center {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
	}
}

.MuiDialog-root {
	.MuiPaper-root {
		width: 600px;
	}

	.MuiDialogContent-root {
		.MuiFormControl-root {
			margin-bottom: 24px;
		}
	}
}

.validation-text {
	color: #d32f2f;
	font-size: 0.75rem;
	margin: 0;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.status {
	font-weight: bold;

	&.open {
		color: green;
	}

	&.closed {
		color: red;
	}
}

.campaign-closed-notice {
	font-size: 0.9rem;
	font-style: italic;
	color: rgb(92, 92, 92);
}

.payment-status-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	.status-text {
		margin-left: 6px;
		font-weight: 600;

		&.all-paid {
			color: green;
		}

		&.some-paid {
			color: orange;
		}

		&.none-paid {
			color: red;
		}

		&.item-cancelled {
			color: gray;
		}

	}
	.tick-icon {
		color: #058c42;
	}
	
	.cross-icon {
		color: #cc2936;
	}
	
	.mid-icon {
		color: #eac435;
	}
}

.hyperlink {
	color: #1976d2;
	text-decoration: underline;
	cursor: pointer;
}

.MuiPopover-root {
	z-index: 1600 !important;
}

.MuiInputAdornment-root .clickable-input-icon:hover {
	cursor: pointer;
	color: crimson;
}

.w-100 {
	width: 100% !important;
}

//Quill override css classes
.ql-editor {
	img {
		max-width: 100%;
	}

	.ql-align-right {
		text-align: right;

		img {
			max-width: 100%;
		}
	}

	.ql-align-center {
		text-align: center;

		img {
			max-width: 100%;
		}
	}

	.ql-align-left {
		text-align: left;

		img {
			max-width: 100%;
		}
	}
}

.fixed-action-section {
	position: fixed;
	left: 0;
	bottom: 0;
	width: calc(100% - 24px);
	background-color: white;
	z-index: 1000;
	padding: 12px;
	box-shadow: 0 -2px 4px -1px rgba(0, 0, 0, 0.2), 0 -4px 5px 0 rgba(0, 0, 0, 0.14), 0 -1px 10px 0 rgba(0, 0, 0, 0.12);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	min-height: 32px;

	a {
		text-decoration: unset;
		text-decoration: unset;
		color: unset;
	}

	.save-button-wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-end;

		.validation-text {
			margin-bottom: 6px;
		}
	}
}

/************ footer changes ***********/
footer {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}

html {
	height: 100%;
}

body {
	min-height: calc(100vh - 64px);
	position: relative;
}


//Tablets
@media only screen and (max-width: 1180px) {
	.MuiButton-root.gg-button {
		padding: 12px 26px;
	}
}



@media only screen and (max-width: 635px) {

	.header-section {
		padding: 12px;
	}


}

@media only screen and (max-width: 600px) {
	.content {
		min-height: calc(100vh - 258px);
		// min-height: calc(100vh - 405px);
	}

	.header-section {
		margin-top: 56px;
	}

	.hide-on-mobile {
		display: none !important;
	}

	.MuiDialog-root {
		.MuiPaper-root {
			width: 100%;
			margin: 12px;
		}

		.MuiDialogContent-root {
			.MuiFormControl-root {
				margin-bottom: 24px;
			}
		}

		.MuiDialogActions-root {
			.MuiButton-root {
				width: 100%;
				height: 48px;
			}
		}
	}

	.validation-text {
		text-align: center;
	}

	.input-wrapper {
		.field-wrapper {
			width: 100%;

			&:nth-child(odd) {
				margin-right: 0;
			}
		}
	}

	.MuiButton-root.gg-button {
		width: 100%;
	}

	.fixed-action-section {
		justify-content: center;
		flex-direction: column;

		// .hide-on-mobile{
		//     display: none;
		// }

		.save-button-wrapper {
			width: 100%;
		}
	}

	.campaign-closed-notice {
		text-align: center;
		padding-bottom: 6px;
	}
}