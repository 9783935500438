.revoke-licence-bundle-dialog{
    .licences-to-revoke{
        display: block;
        font-size: 1.2rem;
        font-weight: bold;
        margin: 24px 0 0 0;
    }

    .remaining-licences{
        display: block;
        font-size: 0.9rem;
        margin-bottom: 12px;
    }

    .MuiPaper-root {
        width: 450px;
    }

    .MuiDialogContent-root{
        padding: 0;

        .coloured-section{
            padding: 50px 12px;
            display: flex;
            align-items: center;
            justify-content: center;

            img{
                width: 150px;
                height: 150px;
            }
        }

        .content-section{
            padding: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }
    }
}