.licence-purchase-dialog {
	.intro-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 12px;
		.intro {
			font-size: 0.8rem;
		}
		.discount {
			text-align: center;
			position: absolute;
			top: 16px;
			right: 24px;
			font-weight: bold;
            background-color: #ADFC92;
            padding: 12px;
            border-radius: 6px;
		}
	}
	.pricing-wrapper {
		display: flex;
		flex-direction: row;

		.pricing-card {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding: 12px;
			cursor: pointer;
            -webkit-touch-callout: none; /* iOS Safari */
			-webkit-user-select: none; /* Safari */
			-khtml-user-select: none; /* Konqueror HTML */
			-moz-user-select: none; /* Old versions of Firefox */
			-ms-user-select: none; /* Internet Explorer/Edge */
			user-select: none;

			.top-group {
				display: flex;
				flex-direction: column;
				justify-content: center;
			}

			&.selected {
				background-color: #26C0B9;
				> * {
					color: white;
				}
			}

			.label {
				// font-size: 0.9rem;
			}

			.discounted-price {
				text-align: center;
				text-decoration: line-through;
				margin-top: 24px;
			}

			.price {
				text-align: center;
				font-size: 1.8rem;
			}

			.per-licence-breakdown {
				text-align: center;
				font-size: 0.8rem;
				margin: 0 0 24px 0;
			}

			.quote {
				justify-content: center;
				align-items: center;
				display: flex;
				font-size: 0.8rem;
				text-align: center;
				font-style: italic;
				min-height: 30px;
			}

			&:not(:last-child) {
				margin-right: 12px;
			}

			> * {
				cursor: pointer;
			}
		}
	}

    .actions-wrapper{

        .tsandcs-checkbox{
                position: relative;
                left: -3px;


            .MuiCheckbox-root{
                // padding-right: 0;
                position: relative;
                left: -5px;
            }

            .MuiTypography-root{
                font-size: 0.8rem;
            }
        }


        .buttons-wrapper{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }
    }

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .licence-purchase-dialog {
		.intro-wrapper {

			// display: flex;
		flex-direction: column;
		// justify-content: space-between;
		// margin: 12px 0;

			.intro {

			}
			.discount {
				text-align: center;
			position: relative;
			top: 0px;
			right: 0px;
			margin-top: 12px;
			padding: 6px 12px;
			// font-weight: bold;
            // background-color: #ADFC92;
            // padding: 12px;
            // border-radius: 6px;

			}
		}
		.pricing-wrapper {
			flex-direction: column;
			.MuiPaper-root{
				margin: 0 0 12px 0;
				width: unset;

			}
	
			.pricing-card {

	
				.top-group {

				}
	
				&.selected {
	
				}
	
				.label {
					// font-size: 0.9rem;
				}
	
				.discounted-price {

				}
	
				.price {

				}
	
				.per-licence-breakdown {
	
				}
	
				.quote {

				}
	
				&:not(:last-child) {
					margin-right: 0;
				}

			}
		}
	
		.actions-wrapper{
			width: 100%;
			display: flex;
			justify-content: flex-end;
			flex-direction: column;
		
			.buttons-wrapper{
				flex-direction: column;
				#next-button{
					order: 1;
				}
				#cancel-button{
					order: 2;
				}
			}
		}
	
	}
}