.big-screen-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 12px 2px;

    .lot-number-wrapper {
        border-top-left-radius: 6px;
        width: 75px;
        height: 75px;
        font-size: 1.3rem;

        &::after{
            border-width: 40px;
        }
    }

    .MuiCardMedia-root {
        height: 40vh;
    }

    .MuiCardContent-root {
        display: flex;
        flex-direction: column;
        flex: 1 1;

        .title-wrapper {
            display: flex;
            flex: 1 1;
        }
        .title {
            font-size: 2.5rem;
            line-height: 2.8rem;
            font-weight: 600;
        }

        .lot-specifics-wrapper {
            padding-top: 6px;

            > div {
                display: flex;
                flex-direction: row;
                font-size: 1.4rem;

                &:not(:last-child) {
                    margin-bottom: 12px;
                }

                .label {
                    // font-size: 0.8rem;
                    // line-height: 1.1rem;
                }
                .value {
                    font-weight: bold;

                    .min-bid-text {
                        font-size: 0.9rem;
                    }

                    &.reserve {
                        // color: red;
                    }
                }

                .multiple-versions-text {
                    margin: 24px 0;
                }
            }
        }
    }
}
