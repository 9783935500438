.footer {
    margin-top: auto;
    
    .footer-section {
        display: flex;
        flex-grow: 1;
        flex-direction: row;
        padding: 24px;
        align-items: center;
        min-height: 48px;
        background-color: #121824;
        color: white;
        bottom: 0;
        margin: 12px 0 0 0;

        .left-content {
            display: flex;
            flex: 1;
            flex-direction: column;
            font-size: 0.7em;

            span {
                margin-bottom: 6px;
            }

            .website-wrapper,
            .email-wrapper {
                display: flex;
                align-items: center;
                svg {
                    margin-right: 6px;
                }
            }
        }

        .middle-content {
            display: flex;
            flex-grow: 1;
            flex-basis: 0;
            justify-content: center;
            text-align: center;
            font-size: 0.7em;
            flex-direction: column;

            a {
                margin-top: 6px;
            }

            img {
                width: 50px;
                height: 45px;
                margin-bottom: 12px;
            }
        }

        .right-content {
            display: flex;
            flex-direction: column;
            flex: 1;
            // justify-content: flex-end;
            align-items: flex-end;
            font-size: 0.7em;

            .social-wrapper {
                display: flex;

                a {
                    display: flex;
                    .social-icon {
                        width: 24px;
                        height: 24px;
                        margin-left: 12px;
                    }
                }
            }

            .terms-wrapper:not(:first-child) {
                padding-top: 10px;
            }
        }

        a {
            color: inherit;
        }
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .footer {
        .footer-section {
            flex-direction: column;

            .left-content {
                order: 1;
                text-align: center;
                padding-bottom: 12px;

                .website-wrapper, .email-wrapper{
                    flex-direction: column;
                    margin-bottom: 12px
                }
            }

            .middle-content {
                order: 2;
                padding-bottom: 12px;
                text-align: center;
            }

            .right-content {
                order: 3;
                padding-bottom: 12px;
                text-align: center;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
