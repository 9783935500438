.admin-home{
    #mobile-select-menu{
        display: none;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .admin-home{

        .MuiBox-root{
            display: none;
        }

        #mobile-select-menu{
            display: block;
        }

        .MuiTabPanel-root{
            padding: 12px 6px;
        }
    }
}