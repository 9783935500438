.bid-report-page {
    .mobile-bid-wrapper {
        display: none;
    }

    .failed {
        font-weight: bold;
        color: red;
    }

    .pending {
        font-weight: bold;
        color: rgb(255, 153, 0);
    }

    .succeeded {
        font-weight: bold;
        color: green;
    }
}

@media only screen and (max-width: 1250px) {
    .bid-report-page {
        .mobile-bid-wrapper {
            display: flex;
            flex-direction: column;
        }

        .desktop-bid-table {
            display: none;
        }
    }
}