.error-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px !important;
    text-align: center;
    margin-top: 117px;

    .MuiAccordion-root{
        width: 100%;
        margin-bottom: 24px;
    }
}

@media only screen and (max-width: 635px) {
    .error-wrapper.content {
        padding-left: 12px;
        padding-right: 12px;
    }
}