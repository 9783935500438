.media-carousel {
	.swiper {
		width: 100%;

		.swiper-slide {
			&.swiper-slide-active {
				opacity: 1;
			}

			img {
				width: 100%;
				max-height: 300px;
				display: block;
				-webkit-background-size: cover;
				background-size: cover;
				background-repeat: no-repeat;
				-webkit-background-position: center;
				background-position: center;
				object-fit: cover;

				&.swiper-lazy {
					min-height: 200px;
                    background-color: #FFFFFF
				}
			}

			.swiper-lazy-preloader-custom {
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0%;
				top: 0%;
				z-index: 10;
                opacity: 0.7;
				background-color: hsl(0, 0%, 85%);
				// animation: 1.5s skeleton-loading linear infinite alternate;	
			}

            // @keyframes skeleton-loading {
            //     0% {
            //       background-color: hsl(0, 0%, 85%);
            //     }
              
            //     100% {
            //       background-color: hsl(0, 0%, 95%);
            //     }
            //   }
		}

		.swiper-button-prev,
		.swiper-button-next {
			color: #fff;
			text-shadow: 0px 2px 3px black;

			//stop highlighting on double click
			-webkit-tap-highlight-color: transparent;
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;

			&:after {
				font-size: 1.5rem;
			}
		}

		.swiper-pagination {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			top: 0px;
			// right: 10px;
			width: auto !important;
			left: auto !important;
			margin: 3px 0 0;
			opacity: 1;
		}
		.swiper-pagination-bullet {
			background-color: #000;
			width: 10px;
			height: 10px;
			display: inline-block;
			border-radius: 50%;
			opacity: 0.4;

			&.youtube {
				background: unset;
				border-radius: unset;

				polygon {
					fill: #000;
					stroke: #000;
					stroke-width: 80;
					margin: 0 4px;
				}
			}
		}

		.swiper-pagination-bullet-active {
			background-color: var(--header-background-color);
			opacity: 1;

			&.youtube {
				polygon {
					fill: var(--header-background-color);
					stroke: var(--header-background-color);
				}
			}
		}
	}
}

@media only screen and (max-width: 688px) {
	.media-carousel {
		.swiper {
            .swiper-slide {  
                img {
                    &.swiper-lazy {
                        min-height: 300px;
                    }
                }
            }
			.swiper-button-next-unique,
			.swiper-button-prev-unique {
				&:hover {
					background-color: transparent;
				}
			}
		}
	}
}
