.tags-section {
    .input-wrapper {
        .sub-section-wrapper {
            display: flex;
            flex-direction: column;

            &:not(:last-child) {
                margin-bottom: 24px;
            }

            .tag-wrapper {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                .new-tag-wrapper{
                    display: flex;
                    justify-content: flex-end;
                    margin-right: 12px;

                    .remove-button{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 0.7em;
                        background-color: red;
                        color: white;
                        border-radius: 50%;
                        width: 15px;
                        height: 15px;
                        position: relative;
                        top:-5px;
                        left: -9px;
                        cursor: pointer;
                    }

                    .MuiButton-root.filter-button{
                        margin-right:0;
                        cursor: unset;
                    }
                }
            }

            .new-tag-input-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;

                .tag-input-wrapper {
                    display: flex;
                    flex-direction: column;
                }

                .MuiTextField-root {
                    margin-right: 12px;
                }
            }

            .no-tags {
                font-size: 0.9em;
                color: gray;
                margin-bottom: 12px;
                font-style: italic;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .tags-section {
        .input-wrapper {
            .sub-section-wrapper {
                display: flex;
                flex-direction: column;
    
                &:not(:last-child) {
                    margin-bottom: 24px;
                }
    
                .tag-wrapper {
                    flex-direction: column;

                    .MuiButton-root.filter-button:not(:last-child){
                        margin-right: 0;
                    }

                    .new-tag-wrapper{
                        margin-right: unset;
                       .MuiButton-root{
                           flex-grow: 1;
                       } 
                    }
                }

                .new-tag-input-wrapper{
                    flex-direction: column;

                    .MuiTextField-root{
                        margin: 0 0 12px 0;
                        width: 100%;
                    }
                }
            }
        }
    }
}