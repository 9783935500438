.error-button-fab-wrapper {
    z-index: 1100;
    position: fixed;
    left: 24px;
    bottom: 24px;
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    transition: transform 0.5s;

    &.show {
        -webkit-transform: none;
        transform: none;
    }

    .MuiFab-root {
        &:hover {
            scale: 1.05;
            transition: scale 0.1s;
        }
    }
}