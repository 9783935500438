.menu-bar {
   
    .MuiAppBar-root {
        position: fixed;
        box-shadow: none;
    }

    .MuiAppBar-colorPrimary {
        flex-grow: 1;
        background-color: #26C0B9;
        border-bottom: solid 1px #22b1aa;
        color: white;


        .authorised-email{
            display: flex;
            cursor:pointer;

            .username{
                display: flex;
                align-items: center;
            }

            svg{
                color: white;
                margin-left: 6px;
                
            }
        }
    }

    .MuiToolbar-root {
        justify-content: space-between;
        // padding: 6px 24px;

        .logo-wrapper{
            a{
                display: flex;

                img{
                    max-height: 50px;
                }
            }
        }
    }

    .hamburger-menu {
        display: none;
        color: white;
        justify-content: flex-end;
        &:hover {
            background-color: transparent;
            color: #e2e2e2;
        }

        .MuiButton-base {
            .MuiButton-label {
                width: 24px;
            }
        }
    }
}

@media only screen and (max-width: 850px) {
    .menu-bar {
        .MuiToolbar-root {
            padding-left: 12px;
        }

        .hamburger-menu {
            display: flex;
        }

        .menu-items {
            .username{
                display: none;
            }
        }

        .logo-wrapper {

            a {   
                svg{
                    width: 167px;
                    height: 70px;
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .menu-bar {
        .MuiAppBar-root{
            height: 56px;
            justify-content: center;
            
        }

        .MuiAppBar-colorPrimary {
            .authorised-email{
                .username{
                    display: none;
                }
            }
        }
    }
}
