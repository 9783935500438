.winner-list-section{

    .summary-wrapper{
        display: flex;
        flex-direction: column;

        // .payment-status-wrapper{
        //     display: flex;
        //     flex-direction: row;
        //     justify-content: flex-start;
        //     margin-top: 6px;

        //     svg{
        //         margin-left: 6px;
        //     }

        //     .status-text{
        //         margin-left: 6px;
        //         font-weight: 600;
        //         &.all-paid{
        //             color: green;
        //         }
        //         &.some-paid{
        //             color: orange;
        //         }
        //         &.none-paid{
        //             color: red;
        //         }
        //     }
        // }
    }

    .MuiTabs-root{
        position: relative;
        .error-icon{
            position: absolute;
            top: 4px;
        }
    }

    .audit-log-section{
        .type-wrapper{
            display: flex;
            align-items: center;

            .error-icon{
                margin-right: 3px;
            }
        }
    }



    .button-wrapper{
        margin-top: 0;
        .winner-actions{
            width: 250px;
        }
    }

    .error-icon{
        color: #d32f2f;
        font-size: 0.95rem;
    }

}