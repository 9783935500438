.big-screen {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;

    .top-section-wrapper{

        // height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;

        section.header {
            background-color: red;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            // height: 15vh;
            padding: 12px;
    
            .logo-wrapper{
                width: 180px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                img {
                    width: 100%;
                    display: block;
                    -webkit-background-size: cover;
                    background-size: cover;
                    background-repeat: no-repeat;
                    -webkit-background-position: center;
                    background-position: center;
                    object-fit: cover;
                }
            }
    
            .title-wrapper{
                // font-size: 2.5rem;
                margin: 0 24px;
                text-align: center;
            }
    
            .qr-wrapper{
                // min-width: 120px;
                width: 120px;
                // height: 120px;
                // margin-left: 60px;
                img {
                    width: 100%;
                    display: block;
                    -webkit-background-size: cover;
                    background-size: cover;
                    background-repeat: no-repeat;
                    -webkit-background-position: center;
                    background-position: center;
                    object-fit: cover;
                }
            }
        }
    
        section.lots {
            display: flex;
            flex-direction: column;
            flex: 1;

            .swiper{
                .swiper-wrapper{
                    height: unset !important;
                    display: flex;
                    align-items: stretch;

                    .swiper-slide{
                        display: flex;
                    }
                }
            }
        }
    }

    section.footer {
        padding: 12px;
        min-height: 30px;
        font-size: 1.4rem;
    }
}
