.lot-report-page {
    .MuiCardContent-root{
        overflow-x: auto;
    }
}

@media only screen and (max-width: 1250px) {
    .lot-report-page {
        // .mobile-lots-wrapper {
        //     display: flex;
        //     flex-direction: column;
        // }

        // .desktop-lots-table {
        //     display: none;
        // }
    }
}
