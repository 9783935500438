.item-catalogue{
    .default-campaign-wrapper{
        // position: relative;
        // top: -24px;
        font-size: 0.8rem;
        margin-bottom: 24px;

        .default-campaign-row{
            display: flex;
            flex-direction: row;
            align-items: center;

            svg{
                font-size: 1.1rem;
                cursor: pointer;
                
                &:hover{
                    color: #DC143C;

                }
            }
        }

    }

    .MuiAvatar-root{
        min-height: 120px;
        aspect-ratio: 1.5;
        cursor: pointer;
    }
}