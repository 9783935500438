.close-campaign-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    // >*{
    //     margin-bottom: 24px;
    // }

    .actions-wrapper{
        display: flex;
        flex-direction: column;
        margin-top: 24px;
        align-items: center;
    }

    .warning-text{
        color: red;
        font-style: italic;
    }

    .notification-settings-text{
        font-size: 0.8rem;
        font-style: italic;
        color: grey;
        margin-top: 6px;

        &.error{
            color: red;
        }
    }

    
}