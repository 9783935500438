.organisation-list-page {

    .MuiCard-root{
        margin-top: 24px;
    }

    a{
        color: unset;
        text-decoration: unset;
    }

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .organisation-list-page {
        .button-wrapper{
            a{
                width: 100%;
            }
        }

    }
}