.bid-filter-section {
    margin: 0 12px 12px 0;

    .filter-dropdown-wrapper {
        align-items: center;

        .MuiFormControl-root {
            min-width: 200px;
            margin-right: 12px;
        }

        .clear-filter {
            color: #007aff;
            font-size: 0.85rem;
            margin: 3px 0 0 3px;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

@media only screen and (max-width: 600px) {
    .bid-filter-section {
        margin: 0 0 12px 0;
    
        .filter-dropdown-wrapper {    
            .MuiFormControl-root {
                min-width: unset;
                width: 100%;
                margin-right: 0;
            }
        }
    }
}
