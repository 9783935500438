.bid-notification-dialog {
    .MuiPaper-root {
        max-width: unset;
        width: 75vw;
        height: 75vh;

        .MuiDialogContent-root {
            display: flex;
            padding: 0;

            .left-right-split {
                display: flex;
                flex-direction: row;
                flex: 1 1;

                .image-wrapper {
                    display: flex;
                    width: 40%;
                    img {
                        width: 100%;
                        flex: 1 1;
                        display: block;
                        -webkit-background-size: cover;
                        background-size: cover;
                        background-repeat: no-repeat;
                        -webkit-background-position: center;
                        background-position: center;
                        object-fit: cover;
                    }
                }

                .text-wrapper {
                    width: 60%;
                    padding: 24px;
                    display: flex;
                    flex-direction: column;

                    h1.title{
                        font-size: 5rem;
                        margin: 0 0 24px 0;
                    }

                    h2.lot-name{
                        font-size: 3.5rem;
                        line-height: 4rem;
                        margin: 0;
                        flex: 1 1;
                    }

                    h3.bidder, h3.bid-amount{
                        font-size: 2.75rem;
                        margin: 0;
                    }
                }
            }
        }
    }
}
