.lot-settings-page{
    .save-button-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .validation-text{
        margin-bottom: 6px;
    }
}
}
