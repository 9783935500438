.lot-list-page {
    .MuiCardContent-root {
        overflow-x: auto;

        &:last-child {
            padding-bottom: 12px;
        }
    }

    .MuiTable-root {
        a {
            color: unset;
        }
    }

    td {
        width: 10%;
    }

    .one-col {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    [data-testid="VisibilityIcon"], [data-testid="VisibilityOffIcon"], [data-testid="ImageIcon"], [data-testid="BurstModeIcon"] {
        color: gray;
    }

    // [data-testid="VisibilityOffIcon"] {
    //     color: gray;
    // }
}

@media only screen and (max-width: 600px) {
    .lot-list-page {
        td {
            width: unset;
        }

        .fixed-action-section {
            .right-hand-buttons {
                width: 100%;
            }
        }
    }
}
